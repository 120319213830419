/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import _ from 'underscore';
import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import LinkList from 'components/LinkList';

import { NavigationItemPropType } from 'propTypes/common';

import styles from './styles.module.scss';

const NavigationItem = ({
	isOpen,
	item,
	openSubMenu,
	closeSubMenu,
	onClickButton,
	type,
	activeUrl,
	theme,
	className,
	linkClassName,
	indexNumber,
	totalItems,
	itemRef
}) => {
	const [childrenTabIndex, setChildrenTabIndex] = useState(-1);
	const listItemRef = useRef(null);
	const firstItemRef = useRef(null);
	const { _id, title, url, children: list } = item;
	const hasSubMenu = !_.isEmpty(list);
	const itemCx = cx(
		styles.item,
		{
			[styles.open]: isOpen,
			[styles.active]: activeUrl && activeUrl.startsWith(url)
		},
		styles[`type_${type}`],
		styles[`theme_${theme}`],
		className
	);

	const ariaText =
		totalItems === 1
			? title
			: `Пункт меню ${title}. ${indexNumber + 1} из ${totalItems}`;

	const onMouseEnterHandle = useCallback(() => {
		if (type !== 'mobile') {
			openSubMenu(_id, listItemRef);
		}
	}, [openSubMenu, _id, listItemRef]);

	const onMouseLeaveHandle = useCallback(() => {
		if (type !== 'mobile') {
			closeSubMenu(_id, listItemRef);
		}
	}, [closeSubMenu, _id, listItemRef]);

	const onClickHandle = useCallback(() => {
		switch (type) {
			case 'mobile': {
				onClickButton(_id, listItemRef);
				if (!isOpen) {
					setChildrenTabIndex(0);
					firstItemRef.current?.focus();
				} else {
					setChildrenTabIndex(-1);
					listItemRef.current?.focus();
				}
				break;
			}
			default: {
				break;
			}
		}
	}, [onClickButton, _id, listItemRef]);

	const hanldeKeyDown = (event) => {
		if (event.target !== event.currentTarget) {
			return;
		}
		if (event.key === 'Enter') {
			event.preventDefault();
			setChildrenTabIndex(0);
			firstItemRef?.current?.focus();
			if (type === 'mobile') {
				if (!isOpen) {
					openSubMenu(_id, listItemRef);
				} else {
					closeSubMenu();
				}
			}
		}
	};

	const handleBlur = (event) => {
		if (!listItemRef.current.contains(event.relatedTarget) && isOpen) {
			onMouseLeaveHandle();
			setChildrenTabIndex(-1);
		}
	};

	const handleFocus = () => {
		if (type !== 'mobile' && !isOpen) {
			openSubMenu(_id, listItemRef);
		}
		if (type === 'mobile' && isOpen) {
			closeSubMenu();
		}
	};

	return (
		<li ref={listItemRef} className={itemCx} onBlur={handleBlur}>
			{url && !hasSubMenu ? (
				<a
					href={url}
					role="menuitem"
					className={cx(styles.link, linkClassName)}
				>
					<div className={styles.title}>{title}</div>
				</a>
			) : (
				<>
					<button
						role="menuitem"
						className={cx(styles.button, linkClassName)}
						type="button"
						onClick={onClickHandle}
						onKeyDown={hanldeKeyDown}
						onFocus={handleFocus}
						aria-label={ariaText}
						onMouseEnter={onMouseEnterHandle}
						ref={itemRef}
					>
						<span className={styles.title}>{title}</span>
					</button>
					{hasSubMenu && (
						<div
							className={styles.linkList}
							tabIndex={-1}
							onMouseLeave={onMouseLeaveHandle}
						>
							<LinkList
								list={list}
								type={type}
								tabIndex={childrenTabIndex}
								firstItemRef={firstItemRef}
							/>
						</div>
					)}
				</>
			)}
		</li>
	);
};

NavigationItem.propTypes = {
	item: NavigationItemPropType.isRequired,
	isOpen: PropTypes.bool,
	openSubMenu: PropTypes.func,
	closeSubMenu: PropTypes.func,
	onClickButton: PropTypes.func,
	type: PropTypes.oneOf(['mobile']),
	theme: PropTypes.string,
	activeUrl: PropTypes.string,
	className: PropTypes.string,
	linkClassName: PropTypes.string,
	indexNumber: PropTypes.number,
	totalItems: PropTypes.number,
	itemRef: PropTypes.object
};

NavigationItem.defaultProps = {
	isOpen: false,
	openSubMenu: _.noop,
	closeSubMenu: _.noop,
	onClickButton: _.noop
};

export default memo(NavigationItem);
