/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'underscore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { NavigationPropType } from 'propTypes/common';
import NavigationItem from './components/Item';

import styles from './styles.module.scss';

const Navigation = ({
	data,
	showActiveItem,
	onSetActiveItem,
	theme,
	type,
	initialFirstItemRef = null
}) => {
	const [activeItemId, setActiveItemId] = useState(null);
	const [activeItemRef, setActiveItemRef] = useState({});
	const localFirstItemRef = useRef();
	const firstItemRef = initialFirstItemRef || localFirstItemRef;

	useEffect(() => {
		if (!showActiveItem) {
			setActiveItemId(null);
			setActiveItemRef({});
		}
	}, [showActiveItem]);

	const closeSubMenu = useCallback(() => {
		setActiveItemId(null);
		setActiveItemRef({});
	}, [type]);

	const setActiveItem = useCallback(
		(_id, ref) => {
			const itemId = activeItemId === _id ? null : _id;
			const itemRef = itemId ? ref : {};

			setActiveItemId(itemId);
			setActiveItemRef(itemRef);

			if (itemId && onSetActiveItem) {
				onSetActiveItem(itemRef, activeItemRef);
			}
		},
		[activeItemId, activeItemRef]
	);

	const openSubMenu = useCallback(
		(_id, ref) => {
			setActiveItem(_id, ref);
		},
		[activeItemId, type]
	);

	const onClick = useCallback(
		(_id, ref) => {
			setActiveItem(_id, ref);
		},
		[setActiveItem, type]
	);

	const navigationCx = cx(
		styles.nav,
		styles[`theme_${theme}`],
		styles[`type_${type}`]
	);

	const handleLeaveNavigation = () => {
		if (type !== 'mobile') {
			closeSubMenu();
		}
	};

	return (
		<nav className={navigationCx} onMouseLeave={handleLeaveNavigation}>
			<ul className={styles.list} role="menubar" aria-label="Меню">
				{_(data).map((item, index) => (
					<NavigationItem
						itemRef={index === 0 ? firstItemRef : null}
						key={item._id}
						item={item}
						isOpen={item._id === activeItemId}
						type={type}
						openSubMenu={openSubMenu}
						closeSubMenu={closeSubMenu}
						onClickButton={onClick}
						theme={item.name}
						className={styles.item}
						linkClassName={styles.link}
						indexNumber={index}
						totalItems={data.length}
					/>
				))}
			</ul>
		</nav>
	);
};

Navigation.propTypes = {
	data: NavigationPropType.isRequired,
	showActiveItem: PropTypes.bool,
	onSetActiveItem: PropTypes.func,
	theme: PropTypes.oneOf(['main', 'footer']),
	type: PropTypes.oneOf(['mobile']),
	initialFirstItemRef: PropTypes.object
};

Navigation.defaultProps = {
	showActiveItem: true
};

export default Navigation;
